<footer id="ts-footer">
    <section id="contact" class="ts-separate-bg-element" data-bg-image="assets/img/bg-desk.jpg" data-bg-image-opacity=".1" data-bg-color="#4345d2">
        <div class="container">
            <div class="ts-box mb-0 p-5 ts-mt__n-10">
                <div class="row">
                    <div class="col-md-12">
                        <h3 class="pull-left" style="margin: 12px 0;">Discuss your Project </h3>
                        <a href="tel:01294017626" class="btn btn-light btn-lg pull-right btn-discuss">7033883934</a>
                    </div>
                </div>
                <!--end row-->
            </div>
            <!--end ts-box-->
            <div class="text-white py-4">
                <div class="row">
                    <div class="col-md-12">
                        <h2 style="margin: 0;">EwayOrbit</h2>
                    </div>
                </div>
                <div class="row footer-menu">
                    <div class="col-md-4">
                        <h5>&nbsp;</h5>
                        <ul class="footer-link">
                            <li><i class="fa fa-phone"></i> +91 88604 25311</li>
                            <li><i class="fa fa-whatsapp"></i> +91 88604 25311</li>
                            <li><i class="fa fa-envelope"></i>&nbsp;<a href="mailto:b2b@ewayorbit.com">b2b@ewayorbit.com</a></li>
                        </ul>

                        <!-- <a href="https://www.facebook.com/ewayorbit/" class="fa fa-facebook social-icon"></a>
                        <a href="https://twitter.com/ewayorbit/" class="fa fa-twitter social-icon"></a> -->
                    </div>
                    <div class="col-md-4">
                        <h5>Company</h5>
                        <ul class="footer-link">
                            <li><a class="nav-item" routerLink="Aboutus">About Us</a></li>
                            <li><a class="nav-item" routerLink="Contactus">Contact Us</a></li>
                            <li><a class="nav-item" routerLink="Blog">Blog</a></li>
                        </ul>
                    </div>
                    <div class="col-md-4">
                        <h5>Products</h5>
                        <ul class="footer-link">
                            <li><a class="nav-item" routerLink="product/Whitelabel">Whitelabel</a></li>
                            <li><a class="nav-item" routerLink="product/API">API Integration</a></li>
                            <li><a class="nav-item" routerLink="product/SME">SME</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="sub-footer text-white py-4">
            <div class="container">
                <span class="text-left">
                    <small>© 2018 Ewayorbit, All Rights Reserved <a class="text-white ml-15" routerLink="Terms">Terms</a> <a class="text-white ml-15" routerLink="Privacy">Privacy</a></small> 
                </span>
                <span class="pull-right">
                    <small>Expeditious Technologies Pvt. Ltd.</small>
                </span>
            </div>
        </div>
        <!--end container-->
    </section>
</footer>
<a id="return-to-top" (click)="scrolltotop()"><i class="fa fa-chevron-up"></i></a>